@import '~antd/lib/style/themes/default.less';

.content {
  margin: 24px;
  padding-top: @layout-header-height;
}
.right-side {
  @media (max-width: 991px) {
    margin-top: 60px;
  }
}
@primary-color: #1DA57A;