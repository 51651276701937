.map-wrapper {
  height: 400px;
  @media (min-width: 768px) {
    height: 600px;
  }
  .ant-card-body {
    padding: 0;
  }
}
#map {
  height: 400px;
  @media (min-width: 768px) {
    height: 600px;
  }
}

.round {
  width: 13px;
  height: 13px;
  border-radius: 10px;
  border-color: #494949;
  margin: 0px 2px -1px 4px;
  display: inline-block;
}
.high {
  background: #000000;
}

.low {
  background: #ffffff;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink .7s alternate infinite;
}

@primary-color: #1DA57A;