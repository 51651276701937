@import '~antd/lib/style/themes/default.less';

@pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.header {
  position: relative;
  height: @layout-header-height;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.logo {
  display: inline-block;
  height: @layout-header-height;
  padding: 0 0 0 24px;
  font-size: 20px;
  line-height: @layout-header-height;
  vertical-align: top;
  cursor: pointer;
  img {
    display: inline-block;
    vertical-align: middle;
  }
}


.social-icons {
  float: right;
  margin-right: 16px;
  button {
    margin-left: 8px;
  }
  .fb {
    background: #4268b1;

    &:hover {
      background: darken(#4268b1, 10%);
    }
  }

  .tw {
    background: #1ea1f2;

    &:hover {
      background: darken(#1ea1f2, 10%);
    }
  }

  .gp {
    background: #f03928;

    &:hover {
      background: darken(#f03928, 10%);
    }
  }

  .wa {
    background: #25d366;

    &:hover {
      background: darken(#25d366, 10%);
    }
  }
}

.menu {
  .anticon {
    margin-right: 8px;
  }
  .ant-dropdown-menu-item {
    min-width: 160px;
  }
}

.trigger {
  height: @layout-header-height;
  padding: ~'calc((@{layout-header-height} - 20px) / 2)' 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  &:hover {
    background: @pro-header-hover-bg;
  }
}

@media (max-width: 480px) {
  .trigger {
    padding: ~'calc((@{layout-header-height} - 20px) / 2)' 12px;
  }
}

.right {
  float: right;
  height: 100%;
  overflow: hidden;
  .action {
    display: inline-block;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    > i {
      color: @text-color;
      vertical-align: middle;
    }
    &:hover {
      background: @pro-header-hover-bg;
    }
    &.opened {
      background: @pro-header-hover-bg;
    }
  }
  .search {
    padding: 0 12px;
    &:hover {
      background: transparent;
    }
  }
  .account {
    .avatar {
      margin: ~'calc((@{layout-header-height} - 24px) / 2)' 0;
      margin-right: 8px;
      color: @primary-color;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark {
  height: @layout-header-height;
  .action {
    color: rgba(255, 255, 255, 0.85);
    > i {
      color: rgba(255, 255, 255, 0.85);
    }
    &:hover,
    &.opened {
      background: @primary-color;
    }
    .ant-badge {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

@media only screen and (max-width: @screen-md) {
  .header {
    .ant-divider-vertical {
      vertical-align: unset;
    }
    .name {
      display: none;
    }
    i.trigger {
      padding: 22px 12px;
    }
    .logo {
      position: relative;
      padding-right: 12px;
      padding-left: 12px;
    }
    .right {
      position: absolute;
      top: 0;
      right: 12px;
      background: #fff;
      .account {
        .avatar {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .header {
    .logo {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}
@primary-color: #1DA57A;