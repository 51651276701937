@import '~antd/lib/style/themes/default.less';

.globalFooter {
  margin: 48px 0 60px 0;
  padding: 0 16px;
  text-align: center;

  span {
    color: @text-color-secondary;
    transition: all 0.3s;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  a {
    color: @text-color-secondary;
    transition: all 0.3s;

    &:hover {
      color: @text-color;
    }
  }

  .links {
    margin-bottom: 8px;
  }

  .copyright {
    color: @text-color-secondary;
    font-size: @font-size-base;
  }
}
@primary-color: #1DA57A;